import React from "react";
import bgImageContact from "../../images/contact-bg-image.com.jpg";
import {Box, Button, Flex, Heading, Text} from "@chakra-ui/react";
import { Trans,Link } from "gatsby-plugin-react-i18next";
const AboutUsDescription = () => {

    return(
        <Box
            mb={10}
            width={'full'}
            bgImage={bgImageContact}
            backgroundPosition="center center"
            backgroundRepeat="nor-repeat"
            backgroundSize="cover"
            position="relative"
            display="block"
            pt={"150px"}
            pb={"150px"}
        >
            <Box
                backgroundColor={'black.400'}
                opacity="0.7"
                w="100%"
                height="100%"
                top="0"
                left="0"
                position="absolute"
                borderRadius="inherit"
            />
            <Flex alignItems="center" justifyContent="center"  margin={'auto'}>
                <Box pos={'relative'} padding="10px" width={'3xl'} display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" gap="20px">
                    <Heading as={'h2'} textAlign="center" fontSize="32px" mb={'1.5rem'} color="white">
                        <Trans>Need Financial Advisor For Your Business? Contact Us Today</Trans>
                    </Heading>
                    <Text textAlign="center" color="white">
                        <Trans>Contact Index Description</Trans>
                    </Text>
                    <Button as={Link} to={'/contact'} backgroundColor="green.600" color="white" width="180px" height="45px" _hover={{ backgroundColor: "teal.600" }}>
                        <Trans>Contact</Trans>
                    </Button>
                </Box>
            </Flex>
        </Box>
    )
};

export default AboutUsDescription