import React from "react";
import Main from "../../components/layouts/Main";
import bgImageAboutUs from "../../images/about-us-bg.jpg";
import { Container} from "@chakra-ui/react";
import AboutUsComponent from "../../components/aboutUs/aboutUs";
import AboutUsDescription from "../../components/aboutUs/aboutUsDescription";
import Breadcrumb from "../../components/breadcrumb";
import {graphql} from "gatsby";
import {useTranslation, useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../../components/seo";
import SeoLogo from "../../images/seo-logo.png"
const AboutUs = () => {
    const { t } = useTranslation();
    const{ language } = useI18next();
    const url = language === 'hy' ? 'https://solidaccount.am/about-us' : `https://solidaccount.am/${language}/about-us`
    return (
        <Main>
            <Seo title={t('About Us')} url={url} logo={SeoLogo} />
            <Breadcrumb cover={bgImageAboutUs} title={'About Us'} />
            <Container maxW="8xl">
                <AboutUsComponent from={'contact-us'} />
            </Container>
            <AboutUsDescription />
        </Main>
    )
};

export default AboutUs
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;