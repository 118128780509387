import React from "react";
import { Box, Flex, Image, Heading, Text } from "@chakra-ui/react";
import aboutUs from "../../images/about-us.jpg";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import ReplaceText from "../replaceText";
const AboutUsComponent = ({ from }) => {
    const { t } = useTranslation();
    return (
        <Box mt={16} mb={10}>
            <Flex alignContent="center" alignItems="center" margin="0 auto" pos="relative"
                  flexDirection={{base: 'column', sm: "column", lg: 'row'}}>
                <Box display="inline-block" width={{base: "100%", sm: '100%', lg: "48.6%"}}>
                    <Image src={aboutUs} height="465px" objectFit="cover" borderRadius="10px"/>
                </Box>
                <Box display="inline-block" width={{base: "100%", sm: '100%', lg: "51.4%"}} mt={{base: 10, md: 0}}>
                    <Box pl={{base: 0, sm: '0px', lg: '70px'}} display="flex" flexWrap="wrap" alignItems="center"
                         alignContent="center">
                        <Heading as={'h5'} color={"green.600"} fontSize="18px" mb={'1.5rem'}>
                            <Trans>About Us</Trans>
                        </Heading>
                        <Text fontSize={'32px'} fontWeight="500" lineHeight="2.3rem" color="rgba(14, 35, 35, 1)"
                              mb={'1.5rem'}>
                            <ReplaceText text={t('About Us Description')} />
                        </Text>
                        <Text mb={'1.5rem'}>
                            <Trans>About Us Description 1</Trans>
                        </Text>
                        {from !== 'contact-us' ? (
                            <Box
                                backgroundColor="rgba(248, 248, 248, 1)"
                                borderRadius="10px"
                                borderLeftStyle="solid"
                                borderLeftWidth="5px"
                                borderLeftColor="#005842"
                                mt="20px"
                                padding="20px 80px 20px 30px"
                                pos="relative"
                            >
                                <Box display="flex" flexWrap="wrap" margin="0 auto">
                                    <Text>
                                        <Trans>About Us Description 3</Trans>
                                    </Text>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Text mb={'1.5rem'}>
                                    <ReplaceText text={t('About Us Description 2')} />
                                </Text>
                                <Text>
                                    <Trans>About Us Description 3</Trans>
                                </Text>
                            </>
                        )}
                    </Box>
                </Box>
            </Flex>
        </Box>

    )
}

export default AboutUsComponent