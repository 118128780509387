import React from "react";
import {Box, Flex, Heading, Link, Text} from "@chakra-ui/react";
import {Link as GatsbyLink} from "gatsby";
import { FaAngleRight } from "react-icons/fa";
import { Trans } from "gatsby-plugin-react-i18next";
const Breadcrumb = (
    {
        cover,
        title
    }
) => {

    return(
        <Box
            mt={4}
            mb={10}
            pt="160px"
            pb="160px"
            width={'full'}
            bgImage={cover}
            backgroundPosition="center center"
            backgroundRepeat="nor-repeat"
            backgroundSize="cover"
            position="relative"
            display="block"
        >
            <Box
                backgroundColor={'black.400'}
                opacity="0.7"
                w="100%"
                height="100%"
                top="0"
                left="0"
                position="absolute"
                borderRadius="inherit"
            />
            <Flex alignItems="center" justifyContent="center"  margin={'auto'}>
                <Box pos={'relative'} padding="10px" width={'lg'}>
                    <Heading as={'h2'} color={"white"} textAlign="center" fontSize="32px" mb={'1.5rem'}>
                        <Trans>{title}</Trans>
                    </Heading>
                    <Text textAlign="center" color="white" display='flex' justifyContent="center" alignItems="center">
                        <Link as={GatsbyLink} to={'/'}> <Trans>Home</Trans> </Link> <FaAngleRight style={{ display: "inline-block", color: '#005842' }} /> <Trans>{title}</Trans>
                    </Text>
                </Box>
            </Flex>
        </Box>
    )
};
export default Breadcrumb